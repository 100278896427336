// import React, { memo, Suspense, useLayoutEffect, useState } from 'react';
// import { Routes, Route, useLocation } from 'react-router-dom';
// import { DEFAULT_PATHS } from 'config.js';
// import Login from 'views/default/Login';
// import NotFound from 'views/default/NotFound';
// import useService from 'views/default/useService';

// const RouteIdentifier = ({
//   routes,
//   fallback = <div className="loading" />,
//   notFoundPath = DEFAULT_PATHS.NOTFOUND,
// }) => {
//   const [isLoading, setIsLoading] = useState(true);
//   const { brightAcceptorHandle, shadeAcceptor, setWorkingElements, handleLogout, getWorkingElements } =
//     useService();
//   const location = useLocation();
//   const params = new URLSearchParams(location.search);

//   const { serviceAcceptor, serviceProvider } = getWorkingElements();

//   const { logout = '', refresh = '' } = {
//     logout: params.get('logout'),
//     refresh: params.get('refresh'),
//   };

//   useLayoutEffect(() => {
//     if (logout) {
//       console.log(logout);
//       handleLogout();
//       window.location.href = process.env.REACT_APP_CONTAINER_APP;
//       setIsLoading(false);
//     } else if (serviceAcceptor && serviceProvider) {
//       const brightAcceptor = brightAcceptorHandle();
//       console.log('brightAcceptor', brightAcceptor);

//       const storedAccessRole = brightAcceptor.role;

//       if (refresh) {
//         console.log(refresh);
//         const params = JSON.parse(atob(refresh));
//         console.log('params', params);

//         if (params.token && params.route) {
//           let newServiceAcceptor = shadeAcceptor(
//             { ...brightAcceptor, access_token: params.token },
//             serviceProvider
//           );

//           setTimeout(() => {
//             setWorkingElements(newServiceAcceptor, serviceProvider);
//           }, 100);
//           window.location.href = `${process.env.REACT_APP_CONTAINER_APP}${params.route}`;

//           window.location.href = `${process.env.REACT_APP_ADMIN_APP}${params.route}`;
//           window.location.href = `${process.env.REACT_APP_TEACHER_APP}${params.route}`;
//           window.location.href = `${process.env.REACT_APP_SCHOOL_APP}${params.route}`;

//           // window.location.href = `${process.env.REACT_APP_ADMIN_APP}${params.route}?refresh_redirect=${btoa(
//           //   JSON.stringify({ newServiceAcceptor, serviceProvider })
//           // )}`;
//         } else {
//           window.location.href = `${process.env.REACT_APP_ADMIN_APP}?logout=true`;
//           window.location.href = `${process.env.REACT_APP_TEACHER_APP}?logout=true`;
//           window.location.href = `${process.env.REACT_APP_SCHOOL_APP}?logout=true`;
//         }

//         setIsLoading(false);
//       } else if (storedAccessRole !== null || storedAccessRole !== undefined) {
//         debugger
//         if (storedAccessRole === 'admin') {
//           (async () => {
//             try {
//               const response = await fetch(process.env.REACT_APP_ADMIN_APP);
//               console.log(process.env.REACT_APP_ADMIN_APP);
//               if (response.ok) {
//                 console.info('RESPONSE OK');

//                 window.location.href = `${process.env.REACT_APP_ADMIN_APP}?redirect=${btoa(
//                   JSON.stringify({ serviceAcceptor, serviceProvider })
//                 )}`;
//               } else {
//                 console.error('RESPONSE NOT OK');

//                 window.location.href = process.env.REACT_APP_CONTAINER_APP;
//               }
//             } catch (error) {
//               console.log(error);
//             }
//           })();
//         } else if (storedAccessRole === 'student') {
//           // console.log('storedAccessRole', 'student');
//           // window.location.href = `http://localhost:7003/dashboards/elearning?role=student&encryptedData=${encryptedData}&key=${secretKey}`;
//           (async () => {
//             try {
//               const response = await fetch(process.env.REACT_APP_STUDENT_APP);
//               console.log(process.env.REACT_APP_STUDENT_APP);
//               if (response.ok) {
//                 console.info('RESPONSE OK');

//                 window.location.href = `${process.env.REACT_APP_STUDENT_APP}?redirect=${btoa(
//                   JSON.stringify({ serviceAcceptor, serviceProvider })
//                 )}`;
//               } else {
//                 console.error('RESPONSE NOT OK');

//                 window.location.href = process.env.REACT_APP_CONTAINER_APP;
//               }
//             } catch (error) {
//               console.log(error);
//             }
//           })();
//         }else if (storedAccessRole !== null || storedAccessRole !== undefined) {
//           if (storedAccessRole === 'teacher') {
//             (async () => {
//               try {
//                 const response = await fetch(process.env.REACT_APP_TEACHER_APP);
//                 console.log(process.env.REACT_APP_TEACHER_APP);
//                 if (response.ok) {
//                   console.info('RESPONSE OK');
  
//                   window.location.href = `${process.env.REACT_APP_TEACHER_APP}/utilities/assessment`
//                 } else {
//                   console.error('RESPONSE NOT OK');
  
//                   window.location.href = process.env.REACT_APP_CONTAINER_APP;
//                 }
//               } catch (error) {
//                 console.log(error);
//               }
//             })();
//           }
//         }else if (storedAccessRole !== null || storedAccessRole !== undefined) {
//           if (storedAccessRole === 'school') {
//             (async () => {
//               try {
//                 const response = await fetch(process.env.REACT_APP_SCHOOL_APP);
//                 console.log(process.env.REACT_APP_SCHOOL_APP);
//                 if (response.ok) {
//                   console.info('RESPONSE OK');
  
//                   window.location.href = `${process.env.REACT_APP_SCHOOL_APP}/utilities/assessment`
//                 } else {
//                   console.error('RESPONSE NOT OK');
  
//                   window.location.href = process.env.REACT_APP_CONTAINER_APP;
//                 }
//               } catch (error) {
//                 console.log(error);
//               }
//             })();
//           }
//         }
//       } else {
//         setIsLoading(false);
//       }
//     }

//     setIsLoading(false);
//   }, []);

//   return (
//     <Suspense fallback={fallback}>
//       {!isLoading && (
//         <Routes>
//           <Route path="/" element={<Login />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="*" element={<NotFound />} />
//         </Routes>
//       )}
//     </Suspense>
//   );
// };

// export default memo(RouteIdentifier);

import React, { memo, Suspense, useLayoutEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { DEFAULT_PATHS } from 'config.js';
import Login from 'views/default/Login';
import NotFound from 'views/default/NotFound';
import useService from 'views/default/useService';

const RouteIdentifier = ({
  routes,
  fallback = <div className="loading" />,
  notFoundPath = DEFAULT_PATHS.NOTFOUND,
}) => {
  
  const [isLoading, setIsLoading] = useState(true);
  const { brightAcceptorHandle, shadeAcceptor, setWorkingElements, handleLogout, getWorkingElements } =
    useService();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { logout = '', refresh = '' } = {
    logout: params.get('logout'),
    refresh: params.get('refresh'),
  };

  const restoreSession = () => {
    
    const savedSession = localStorage.getItem('userSession');
    if (savedSession) {
      try {
        const sessionData = JSON.parse(savedSession);
        setWorkingElements(sessionData.serviceAcceptor, sessionData.serviceProvider);
        return sessionData;
      } catch (error) {
        console.error('Error restoring session:', error);
      }
    }
    return null;
  };

  const saveSession = (serviceAcceptor, serviceProvider) => {
    const sessionData = {
      serviceAcceptor,
      serviceProvider,
      expiry: new Date().getTime() + 365 * 24 * 60 * 60 * 1000, // 1 year
    };
    localStorage.setItem('userSession', JSON.stringify(sessionData));
  };

  const clearSession = () => {
    localStorage.removeItem('userSession');
  };

  useLayoutEffect(() => {
    if (logout) {
      handleLogout();
      clearSession();
      window.location.href = process.env.REACT_APP_CONTAINER_APP;
      setIsLoading(false);
      return;
    }

    let sessionData = restoreSession();

    if (!sessionData) {
      const { serviceAcceptor, serviceProvider } = getWorkingElements();
      if (serviceAcceptor && serviceProvider) {
        sessionData = { serviceAcceptor, serviceProvider };
        saveSession(serviceAcceptor, serviceProvider);
      }
    }

    if (sessionData) {
      const { serviceAcceptor, serviceProvider } = sessionData;
      const brightAcceptor = brightAcceptorHandle();

      if (refresh) {
        const params = JSON.parse(atob(refresh));
        if (params.token && params.route) {
          const newServiceAcceptor = shadeAcceptor(
            { ...brightAcceptor, access_token: params.token },
            serviceProvider
          );
          saveSession(newServiceAcceptor, serviceProvider);
          window.location.href = `${process.env.REACT_APP_CONTAINER_APP}${params.route}`;
        } else {
          window.location.href = `${process.env.REACT_APP_ADMIN_APP}?logout=true`;
        }
        setIsLoading(false);
        return;
      }

      const storedAccessRole = brightAcceptor.role;
      if (storedAccessRole) {
        (async () => {
          try {
            let targetApp = process.env.REACT_APP_CONTAINER_APP;
            if (storedAccessRole === 'admin') {
              targetApp = process.env.REACT_APP_ADMIN_APP;
            } else if (storedAccessRole === 'student') {
              targetApp = process.env.REACT_APP_STUDENT_APP;
            } else if (storedAccessRole === 'teacher') {
              targetApp = process.env.REACT_APP_TEACHER_APP;
            } else if (storedAccessRole === 'school') {
              targetApp = process.env.REACT_APP_SCHOOL_APP;
            }

            const response = await fetch(targetApp);
            if (response.ok) {
              window.location.href = `${targetApp}?redirect=${btoa(
                JSON.stringify({ serviceAcceptor, serviceProvider })
              )}`;
            } else {
              window.location.href = process.env.REACT_APP_CONTAINER_APP;
            }
          } catch (error) {
            console.error('Error redirecting:', error);
          }
        })();
        return;
      }else{
        window.location.href = `${process.env.REACT_APP_CONTAINER_APP}`
      }
    }

    setIsLoading(false);
  }, []);

  return (
    <Suspense fallback={fallback}>
      {!isLoading && (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </Suspense>
  );

  
};

export default memo(RouteIdentifier);

