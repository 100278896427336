import React from 'react';

function LoginLeftSide() {
  return (
    <div className="min-h-100 d-flex align-items-center">
      <div className="w-100 w-lg-75 w-xxl-50">
        <div>
          <div className="mb-5">
            <h1 className="display-3 text-white">Hi!</h1>
            <h1 className="display-3 text-white">Ready to Learn</h1>
          </div>
          <p className="h6 text-white lh-1-5 mb-5">Please Login using your registered mobile number and OTP</p>
          {/* <div className="mb-5">
            <Button size="lg" variant="outline-white" href="/">
              Learn More
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default LoginLeftSide;
