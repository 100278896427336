import React from 'react';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import useHandleMobileOTPMethod from './useHandleMobileOTPMethod';

function AuthGetOTP({ initialValues, validationSchema, handleLoading, isLoading, handleIsOTP, handleInitialValues, getOTPButtonRef }) {
  const { performPullOTP, customError, setCustomError } = useHandleMobileOTPMethod(handleInitialValues, handleLoading, handleIsOTP);

  const onSubmit = (values) => {
    performPullOTP(values);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, handleSubmit, handleChange, handleBlur, touched, errors }) => (
        <form id="loginForm" className="tooltip-end-bottom" onSubmit={handleSubmit}>
          <div className="mb-3 filled form-group tooltip-end-top">
            <CsLineIcons icon="phone" />
            <Form.Control
              type="tel"
              name="mobileNumber"
              placeholder="Mobile Number"
              value={values.mobileNumber}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                setCustomError('');
              }}
              label="Mobile Number"
            />
            {errors.mobileNumber && touched.mobileNumber && <div className="d-block invalid-tooltip">{errors.mobileNumber}</div>}
            {customError && touched.mobileNumber && <div className="d-block invalid-tooltip">{customError}</div>}
          </div>

          <Button ref={getOTPButtonRef} size="lg" type="submit">
            {isLoading ? <>Loading...</> : <>Get OTP</>}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default AuthGetOTP;
