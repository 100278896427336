import CryptoJS from 'crypto-js';

const useService = () => {
  const getWorkingElements = () => {
    const serviceAcceptor = localStorage.getItem('service_acceptor');
    const serviceProvider = localStorage.getItem('service_provider');

    return { serviceAcceptor, serviceProvider };
  };
  const shadeAcceptor = (serviceAcceptor, serviceProvider) =>
    CryptoJS.AES.encrypt(JSON.stringify(serviceAcceptor), serviceProvider).toString();
  const lightAcceptor = (serviceAcceptor, serviceProvider) =>
    JSON.parse(CryptoJS.AES.decrypt(serviceAcceptor, serviceProvider).toString(CryptoJS.enc.Utf8));
  const brightAcceptorHandle = () => {
    const { serviceAcceptor, serviceProvider } = getWorkingElements();
    return lightAcceptor(serviceAcceptor, serviceProvider);
  };
  const setWorkingElements = (serviceAcceptor, serviceProvider) => {
    localStorage.setItem('service_acceptor', serviceAcceptor);
    localStorage.setItem('service_provider', serviceProvider);
  };
  const handleLogout = () => {
    localStorage.removeItem('service_provider');
    localStorage.removeItem('service_acceptor');
  };
  return {
    getWorkingElements,
    shadeAcceptor,
    lightAcceptor,
    brightAcceptorHandle,
    setWorkingElements,
    handleLogout,
  };
};

export default useService;
