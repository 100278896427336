import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const OTPVerificationApi = createApi({
  reducerPath: 'OTPVerificationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  endpoints: (builder) => ({
    pullOTP: builder.mutation({
      query: (phoneNumber) => ({
        url: `https://2factor.in/API/V1/e6d1f0ea-fa33-11ed-addf-0200cd936042/SMS/+91${phoneNumber}/AUTOGEN2/INFOPBS`,
        method: 'GET',
        redirect: 'follow',
      }),
      transformResponse: (response) => response,
    }),
    checkPhone: builder.mutation({
      query: (phoneNumber) => ({
        url: `/common/checkPhone`,
        method: 'POST',
        redirect: 'follow',
        body: JSON.parse(JSON.stringify({ phone: phoneNumber, origin: process.env.REACT_APP_CONTAINER_APP })),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': process.env.REACT_APP_CONTAINER_APP,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Access-Control-Allow-Headers':
            'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
          'Access-Control-Allow-Credentials': 'true',
        },
      }),
    }),
    checkOTP: builder.mutation({
      query: (ep) => ({
        url: `https://2factor.in/API/V1/e6d1f0ea-fa33-11ed-addf-0200cd936042/SMS/VERIFY3/+91${ep}`,
        method: 'GET',
        redirect: 'follow',
      }),
      transformResponse: (response) => response,
    }),
    successOTP: builder.mutation({
      query: (values) => ({
        url: `/common/getLoginRole`,
        method: 'POST',
        redirect: 'follow',
        body: JSON.parse(JSON.stringify({ ...values })),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': process.env.REACT_APP_CONTAINER_APP,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Access-Control-Allow-Headers':
            'Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Origin, X-Requested-With, Content-Type, Accept, Authorization, access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
          'Access-Control-Allow-Credentials': 'true',
        },
      }),
    }),
  }),
});

export const { usePullOTPMutation, useCheckPhoneMutation, useCheckOTPMutation, useSuccessOTPMutation } =
  OTPVerificationApi;

export const { OTPVerificationApiReducer } = OTPVerificationApi.reducer;
