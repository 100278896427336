import { useState } from 'react';
import { useCheckPhoneMutation, usePullOTPMutation } from 'slices/get/OTPVerificationApi';

const useHandleMobileOTPMethod = (handleInitialValues, handleLoading, handleIsOTP) => {
  const [customError, setCustomError] = useState('');
  const [checkPhone] = useCheckPhoneMutation();
  const [pullOTP] = usePullOTPMutation();

  const performPullOTP = (values) => {
    handleInitialValues(values);
    console.log('handle login OTP values', values);
    handleLoading(true);

    checkPhone(values.mobileNumber)
      .then((response) => {
        console.log(response);
        if (response.data) {
          return { data: response.data, error: response.data.error };
        }
        return { error: 'Something Went Wrong' };
      })
      .then((data) => {
        console.log(data);
        if (data.error) {
          console.error('Network Error', data.error);
          handleLoading(false);
          setCustomError(data.error);
        } else if (data.data.status === 'Success') {
          pullOTP(values.mobileNumber)
            .then((response) => {
              console.log(response);
              return response.data;
            })
            .then((dataIn) => {
              if (dataIn?.Status) {
                if (dataIn.Status === 'Success') {
                  handleLoading(false);
                  handleIsOTP(true);
                  console.warn('Success True');
                }
              } else {
                setCustomError('Network Issue');
                console.error('login failed');
                handleLoading(false);
              }
            });
        } else {
          console.error('Please check your phone number');
        }
      });
  };

  return { performPullOTP, customError, setCustomError };
};

export default useHandleMobileOTPMethod;
