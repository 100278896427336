import React, { memo, Suspense, useLayoutEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { DEFAULT_PATHS } from 'config.js';
import Login from 'views/default/Login';
import NotFound from 'views/default/NotFound';
import useService from 'views/default/useService';

const RouteIdentifier = ({
  routes,
  fallback = <div className="loading" />,
  notFoundPath = DEFAULT_PATHS.NOTFOUND,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { brightAcceptorHandle, shadeAcceptor, setWorkingElements, handleLogout, getWorkingElements } =
    useService();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { serviceAcceptor, serviceProvider } = getWorkingElements();

  const { logout = '', refresh = '' } = {
    logout: params.get('logout'),
    refresh: params.get('refresh'),
  };

  useLayoutEffect(() => {
    if (logout) {
      console.log(logout);
      handleLogout();
      window.location.href = process.env.REACT_APP_CONTAINER_APP;
      setIsLoading(false);
    } else if (serviceAcceptor && serviceProvider) {
      const brightAcceptor = brightAcceptorHandle();
      console.log('brightAcceptor', brightAcceptor);

      const storedAccessRole = brightAcceptor.role;

      if (refresh) {
        console.log(refresh);
        const params = JSON.parse(atob(refresh));
        console.log('params', params);

        if (params.token && params.route) {
          let newServiceAcceptor = shadeAcceptor(
            { ...brightAcceptor, access_token: params.token },
            serviceProvider
          );

          setTimeout(() => {
            setWorkingElements(newServiceAcceptor, serviceProvider);
          }, 100);

          window.location.href = `${process.env.REACT_APP_ADMIN_APP}${params.route}`;

          // window.location.href = `${process.env.REACT_APP_ADMIN_APP}${params.route}?refresh_redirect=${btoa(
          //   JSON.stringify({ newServiceAcceptor, serviceProvider })
          // )}`;
        } else {
          window.location.href = `${process.env.REACT_APP_ADMIN_APP}?logout=true`;
        }

        setIsLoading(false);
      } else if (storedAccessRole !== null || storedAccessRole !== undefined) {
        if (storedAccessRole === 'admin') {
          (async () => {
            try {
              const response = await fetch(process.env.REACT_APP_ADMIN_APP);
              console.log(process.env.REACT_APP_ADMIN_APP);
              if (response.ok) {
                console.info('RESPONSE OK');

                window.location.href = `${process.env.REACT_APP_ADMIN_APP}/learning/classes?redirect=${btoa(
                  JSON.stringify({ serviceAcceptor, serviceProvider })
                )}`;
              } else {
                console.error('RESPONSE NOT OK');

                window.location.href = process.env.REACT_APP_CONTAINER_APP;
              }
            } catch (error) {
              console.log(error);
            }
          })();
        } else if (storedAccessRole === 'student') {
          // console.log('storedAccessRole', 'student');
          // window.location.href = `http://localhost:7003/dashboards/elearning?role=student&encryptedData=${encryptedData}&key=${secretKey}`;
          (async () => {
            try {
              const response = await fetch(process.env.REACT_APP_STUDENT_APP);
              console.log(process.env.REACT_APP_STUDENT_APP);
              if (response.ok) {
                console.info('RESPONSE OK');

                window.location.href = `${process.env.REACT_APP_STUDENT_APP}/learning/classes?redirect=${btoa(
                  JSON.stringify({ serviceAcceptor, serviceProvider })
                )}`;
              } else {
                console.error('RESPONSE NOT OK');

                window.location.href = process.env.REACT_APP_CONTAINER_APP;
              }
            } catch (error) {
              console.log(error);
            }
          })();
        }
      } else {
        setIsLoading(false);
      }
    }

    setIsLoading(false);
  }, []);

  return (
    <Suspense fallback={fallback}>
      {!isLoading && (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </Suspense>
  );
};

export default memo(RouteIdentifier);
