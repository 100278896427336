import React, { useState } from 'react';
import LayoutFullpage from 'layout/LayoutFullpage';
import HtmlHead from 'components/html-head/HtmlHead';
import LoginRightSide from './LoginRightSide';
import LoginLeftSide from './LoginLeftSide';

const Login = () => {
  const title = 'Login';
  const description = 'Login Page';
  const [isLoading, setIsLoading] = useState(false);

  const handleLoading = (bool) => {
    setIsLoading(bool);
  };

  return (
    <>
      <HtmlHead title={title} description={description} />
      <LayoutFullpage
        left={<LoginLeftSide />}
        right={<LoginRightSide handleLoading={handleLoading} isLoading={isLoading} />}
        isLoading={isLoading}
      />
    </>
  );
};

export default Login;
