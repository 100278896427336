import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import useHandleMobileOTPVerificationMethod from './useHandleMobileOTPVerificationMethod';

function AuthEnterOTP({
  initialValues,
  validationSchemaOTP,
  isLoading,
  handleLoading,
  dispatch,
  secretKey,
  navigate,
  mobileNumber,
  setMobileNumber,
  handleIsOTP,
  handleSendOTPAgain,
}) {
  const { performCheckOTP, customOTPError, setCustomOTPError } = useHandleMobileOTPVerificationMethod(
    handleLoading,
    dispatch,
    secretKey,
    navigate
  );

  const [timer, setTimer] = useState(60); // Timer set to 60 seconds initially

  const onSubmit = (values) => {
    performCheckOTP({ ...values, phone: values.mobileNumber });
  };

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1); // Decrease timer by 1 every second
      }, 1000);
    }
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [timer]);

  useEffect(() => {
    // Start the timer immediately when component mounts
    const timerId = setTimeout(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearTimeout(timerId); // Cleanup timer on component unmount
  }, []); // Empty dependency array to run only once on mount

  const handleResendOTP = () => {
    setTimer(60); // Reset timer to 60 seconds
    handleSendOTPAgain(); // Call the parent function to handle OTP resend
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchemaOTP} onSubmit={onSubmit}>
      {({ values, handleSubmit, handleChange, handleBlur, touched, errors }) => (
        <form
          id="loginForm"
          className="tooltip-end-bottom"
          onSubmit={(e) => {
            handleSubmit(e);
            setMobileNumber(values.mobileNumber);
          }}
        >
          <div className="mb-3 filled form-group tooltip-end-top">
            <CsLineIcons icon="phone" />
            <Form.Control
              type="tel"
              name="mobileNumber"
              placeholder="Mobile Number"
              value={values.mobileNumber}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);

                if (values.mobileNumber !== mobileNumber) {
                  handleIsOTP(false);
                }
              }}
              label="Mobile Number"
            />
            {errors.mobileNumber && touched.mobileNumber && (
              <div className="d-block invalid-tooltip">{errors.mobileNumber}</div>
            )}
          </div>

          <div className="mb-3 filled form-group tooltip-end-top">
            <CsLineIcons icon="login" />
            <Form.Control
              id="outlined-adornment-otp"
              type="tel"
              value={values.otp}
              name="otp"
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                setCustomOTPError('');
              }}
              label="OTP"
              placeholder="Enter OTP"
            />
            {errors.otp && touched.otp && <div className="d-block invalid-tooltip">{errors.otp}</div>}
            {customOTPError && touched.mobileNumber && (
              <div className="d-block invalid-tooltip">{customOTPError}</div>
            )}
          </div>

          <Button size="lg" type="submit">
            {isLoading ? <>Loading...</> : <>Login</>}
          </Button>

          {timer === 0 && (
            <Button size="sm" variant="link" onClick={handleResendOTP}>
              Send OTP again
            </Button>
          )}

          {timer > 0 && (
            <p size="sm" variant="link">
              Resend OTP in {timer} seconds
            </p>
          )}
        </form>
      )}
    </Formik>
  );
}

export default AuthEnterOTP;
