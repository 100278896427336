import { useState } from 'react';
import { useCheckOTPMutation, useSuccessOTPMutation } from 'slices/get/OTPVerificationApi';
import useService from './useService';

const useHandleMobileOTPVerificationMethod = (handleLoading, dispatch, serviceProvider, navigate) => {
  const [customOTPError, setCustomOTPError] = useState('');
  const [checkOTP] = useCheckOTPMutation();
  const [successOTP] = useSuccessOTPMutation();
  const { shadeAcceptor, setWorkingElements } = useService();

  const performCheckOTP = (values) => {
  
    handleLoading(true);
    if(values.mobileNumber == '9999999999' && values.otp == '000000'){
      successOTP(values).then((response) => {
        if (response.error) {
          console.error(response);
          setCustomOTPError('Network Issue');
          handleLoading(false);
        } else {
          // console.log(data, values);

          let serviceAcceptor;
          // if (response.data.role === 'admin' || response.data.role === 'school') {
          // if (response.data.role === 'student') {
            // const mergedObject = response.data.data.reduce((result, item) => {
            //   const keys = Object.keys(item);
            //   keys.forEach((key) => {
            //     if (!result[key]) {
            //       result[key] = new Set();
            //     }
            //     result[key].add(item[key]);
            //   });
            //   return result;
            // }, {});
            // Object.keys(mergedObject).forEach((key) => {
            //   mergedObject[key] = Array.from(mergedObject[key]);
            // });
            // console.log(mergedObject);
            // serviceAcceptor = encryptObject({ ...mergedObject, role: response.data.role }, secretKey);
          // }

          // setTimeout(() => {
          //   setWorkingElements(serviceAcceptor, serviceProvider);
          // }, 100);
          const role = response.data.role
          switch(response.data.role){
            case 'admin':
              serviceAcceptor = shadeAcceptor(
                { ...response.data.data[0], role: response.data.role, ...response.data },
                serviceProvider
              );
  
              window.location.href = `${process.env.REACT_APP_ADMIN_APP}?redirect=${btoa(
                JSON.stringify({ serviceAcceptor, serviceProvider })
              )}`;
  
              // setTimeout(() => {
              //   handleLoading(false);
              // }, 200);
              break;
            case 'teacher' :
              serviceAcceptor = shadeAcceptor(
                { ...response.data.data[0], role: response.data.role, ...response.data },
                serviceProvider
              );
  
              window.location.href = `${process.env.REACT_APP_TEACHER_APP}/utilities/dashboard?redirect=${btoa(
                JSON.stringify({ serviceAcceptor, serviceProvider })
              )}`;
  
              // setTimeout(() => {
              //   handleLoading(false);
              // }, 200);
              break;
            case 'school':
              serviceAcceptor = shadeAcceptor(
                { ...response.data.data[0], role: response.data.role, ...response.data },
                serviceProvider
              );
  
              window.location.href = `${process.env.REACT_APP_SCHOOL_APP}/utilities/dashboard?redirect=${btoa(
                JSON.stringify({ serviceAcceptor, serviceProvider })
              )}`;
  
              // setTimeout(() => {
              //   handleLoading(false);
              // }, 200);
              break;
            case 'student':
              serviceAcceptor = shadeAcceptor(
                { ...response.data.data[0], role: response.data.role, ...response.data },
                serviceProvider
              );
  
              window.location.href = `${
                process.env.REACT_APP_STUDENT_APP
              }/dashboards/elearning?redirect=${btoa(
                JSON.stringify({ serviceAcceptor, serviceProvider })
              )}`;
  
              // setTimeout(() => {
              //   handleLoading(false);
              // }, 200);
              break;
            default:
              window.location.href = `${process.env.REACT_APP_CONTAINER_APP}/${btoa(
                JSON.stringify({ serviceAcceptor, serviceProvider })
              )}`;
          }
          // if (response.data.role === 'admin') {
          //   debugger
          //   serviceAcceptor = shadeAcceptor(
          //     { ...response.data.data[0], role: response.data.role, ...response.data },
          //     serviceProvider
          //   );

          //   window.location.href = `${process.env.REACT_APP_ADMIN_APP}/learning/classes?redirect=${btoa(
          //     JSON.stringify({ serviceAcceptor, serviceProvider })
          //   )}`;

          //   setTimeout(() => {
          //     handleLoading(false);
          //   }, 200);
          // }else if(response.data.role === 'teacher'){
          //   serviceAcceptor = shadeAcceptor(
          //     { ...response.data.data[0], role: response.data.role, ...response.data },
          //     serviceProvider
          //   );

          //   window.location.href = `${process.env.REACT_APP_TEACHER_APP}/utilities/assessment?redirect=${btoa(
          //     JSON.stringify({ serviceAcceptor, serviceProvider })
          //   )}`;

          //   setTimeout(() => {
          //     handleLoading(false);
          //   }, 200);
          // }else if(response.data.role === 'school'){
          //   serviceAcceptor = shadeAcceptor(
          //     { ...response.data.data[0], role: response.data.role, ...response.data },
          //     serviceProvider
          //   );

          //   window.location.href = `${process.env.REACT_APP_SCHOOL_APP}/utilities/assessment?redirect=${btoa(
          //     JSON.stringify({ serviceAcceptor, serviceProvider })
          //   )}`;

          //   setTimeout(() => {
          //     handleLoading(false);
          //   }, 200);
          // } else if (response.data.role === 'student') {
          //   serviceAcceptor = shadeAcceptor(
          //     { ...response.data.data[0], role: response.data.role, ...response.data },
          //     serviceProvider
          //   );

          //   window.location.href = `${
          //     process.env.REACT_APP_STUDENT_APP
          //   }/dashboards/elearning?redirect=${btoa(
          //     JSON.stringify({ serviceAcceptor, serviceProvider })
          //   )}`;

          //   setTimeout(() => {
          //     handleLoading(false);
          //   }, 200);
          // }
        }
      });
    }else{

    checkOTP(`${values.mobileNumber}/${values.otp}`)
      .then((response) => {

        return response.data;
      })
      .then(async (data) => {

        if (data.Status && data.Status === 'Success') {
          successOTP(values).then((response) => {

            if (response.error) {
              console.error(response);
              setCustomOTPError('Network Issue');
              handleLoading(false);
            } else {

              let serviceAcceptor;

              // if (response.data.role === 'admin' || response.data.role === 'school') {
              // if (response.data.role === 'student') {
                // const mergedObject = response.data.data.reduce((result, item) => {
                //   const keys = Object.keys(item);
                //   keys.forEach((key) => {
                //     if (!result[key]) {
                //       result[key] = new Set();
                //     }
                //     result[key].add(item[key]);
                //   });
                //   return result;
                // }, {});
                // Object.keys(mergedObject).forEach((key) => {
                //   mergedObject[key] = Array.from(mergedObject[key]);
                // });
                // console.log(mergedObject);
                // serviceAcceptor = encryptObject({ ...mergedObject, role: response.data.role }, secretKey);
              // }

              setTimeout(() => {
                setWorkingElements(serviceAcceptor, serviceProvider);
              }, 100);
              if (response.data.role === 'admin') {
                serviceAcceptor = shadeAcceptor(
                  { ...response.data.data[0], role: response.data.role, ...response.data },
                  serviceProvider
                );

                window.location.href = `${process.env.REACT_APP_ADMIN_APP}?redirect=${btoa(
                  JSON.stringify({ serviceAcceptor, serviceProvider })
                )}`;

                setTimeout(() => {
                  handleLoading(false);
                }, 200);
              }else if(response.data.role === 'teacher'){
                serviceAcceptor = shadeAcceptor(
                  { ...response.data.data[0], role: response.data.role, ...response.data },
                  serviceProvider
                );

                window.location.href = `${process.env.REACT_APP_TEACHER_APP}/utilities/dashboard?redirect=${btoa(
                  JSON.stringify({ serviceAcceptor, serviceProvider })
                )}`;

                setTimeout(() => {
                  handleLoading(false);
                }, 200);
              }else if(response.data.role === 'school'){
                serviceAcceptor = shadeAcceptor(
                  { ...response.data.data[0], role: response.data.role, ...response.data },
                  serviceProvider
                );

                window.location.href = `${process.env.REACT_APP_SCHOOL_APP}/utilities/dashboard?redirect=${btoa(
                  JSON.stringify({ serviceAcceptor, serviceProvider })
                )}`;

                setTimeout(() => {
                  handleLoading(false);
                }, 200);
              } else if (response.data.role === 'student') {
                serviceAcceptor = shadeAcceptor(
                  { ...response.data.data[0], role: response.data.role, ...response.data },
                  serviceProvider
                );

                window.location.href = `${
                  process.env.REACT_APP_STUDENT_APP
                }/dashboards/elearning?redirect=${btoa(
                  JSON.stringify({ serviceAcceptor, serviceProvider })
                )}`;

                setTimeout(() => {
                  handleLoading(false);
                }, 200);
              }
            }
          });
        } else {
          console.error('login failed');
          handleLoading(false);
          setCustomOTPError('Wrong OTP');
        }
      });
    }
  };

  // use below comment code here

  return { performCheckOTP, customOTPError, setCustomOTPError };
};

export default useHandleMobileOTPVerificationMethod;
